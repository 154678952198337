import * as React from "react";
import { FunctionComponent } from "react";
import { Alert, Button, Grid } from "react-bootstrap";
import { Redirect } from "react-router";
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import Cookies from "universal-cookie";
import affiliates from "./affiliates";
import asyncComponent from "./components/AsyncComponent";
import { Protected } from "./components/Protected";
import SurveyLoader from "./components/SurveyLoader";
import { StartAuthCode } from "./views/StartAuthCode";
import { StartNoCode } from "./views/StartNoCode";
import Success from "./views/Success";

import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";

interface RouteObject {
  path?: string;
  protected?: boolean;
  component: React.ComponentType<any>;
}

interface AppState {
  hasError: boolean;
}

const cookies = new Cookies();

const WildcardComponent: FunctionComponent<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const path = props.location.pathname;

  const a = affiliates.get(path);
  if (a) {
    cookies.set("referred-by", a.referrer, {
      path: "/",
      maxAge: 60 * 60 * 24 * 7,
      sameSite: "strict",
    });
    return <Redirect to={a.applicationPath} />;
  }
  return <Alert bsStyle="warning">404 Not Found.</Alert>;
};

// const renderNoMatch = () => <Alert bsStyle="warning">Not found</Alert>;
const AsyncAdmin = asyncComponent(() => import("./views/Admin"));
const AsyncLogin = asyncComponent(() => import("./views/Login"));
const AsyncAppLogs = asyncComponent(() => import("./views/Logs"));

class App extends React.Component<Record<string, never>, AppState> {
  state: AppState = { hasError: false };

  public render() {
    if (location.hash.startsWith("#/")) {
      const dest = location.hash.replace("#", "");
      // alert(dest);
      window.history.replaceState(null, "wut", dest); // or history.replace
    }
    return (
      <BrowserRouter>
        <Switch>
          {this.renderAdminRoutes()}
          {/* This should always be last because it contains no-match route: */}
          {this.renderPublicRoutes()}
        </Switch>
      </BrowserRouter>
    );
  }

  private readonly noEmail = [
    "protege-spouse",
    "protege-caregiver",
    "mentor-spouse",
    "protege",
    "protege-fumch",
    "protege-sowf",
  ];

  private readonly publicRoutes: RouteObject[] = [
    // is there a better way to do this kind of redirect?
    {
      path: "/mentor-spouse",
      component: () => {
        return <Redirect to={`/mentor`} />;
      },
    },
    {
      path: `/:surveyDefinitionUrl(${this.noEmail.join("|")})`,
      component: StartNoCode,
    },
    {
      path: `/:surveyDefinitionUrl(${this.noEmail.join("|")})/:sp`,
      component: StartNoCode,
    },

    {
      path: "/linkedin",
      component: (props: RouteComponentProps) => {
        cookies.set("referred-by", "LinkedIn", {
          path: "/",
          maxAge: 60 * 60 * 24 * 7,
          sameSite: "strict",
        });
        return <StartAuthCode code="ACPUSA" {...props} />;
      },
    },
    {
      path: "/mentor",
      component: StartAuthCode,
    },

    {
      path: "/a/:uuid/:pane",
      component: SurveyLoader,
    },
    {
      path: "/a/:uuid",
      component: SurveyLoader,
    },
    {
      path: "/success",
      component: Success,
    },
    {
      path: "/login",
      component: AsyncLogin,
    },
    {
      path: "/affiliate-cheatsheet",
      component: () => {
        return (
          <div>
            <table className="data-table">
              <thead>
                <tr>
                  <th>URL</th>
                  <th>Referrer</th>
                  <th>Destination</th>
                </tr>
              </thead>
              <tbody>
                {Array.from(affiliates).map(([path, affiliate]) => {
                  return (
                    <tr key={path}>
                      <td style={{ paddingRight: "0.75em" }}>
                        https://apply.acp-usa.org{path}
                      </td>
                      <td>{affiliate.referrer}</td>
                      <td>/{affiliate.applicationPath}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      },
    },
    // Handy for testing error handling.  Notice the setState() hack used inside an event handler.  This allows our
    // thrown error to be caught and handled by the ErrorBoundary component.  React Error Boundaries don't usually
    // catch stuff thrown in event handlers (see the docs for why that is).
    {
      path: "/force-error",
      component: () => {
        return (
          <div style={{ textAlign: "center", padding: 50 }}>
            <p>
              Experiencing{" "}
              <a
                target="callvoid"
                href="http://bearlamp.com.au/the-call-of-the-void/"
              >
                L'appel du vide
              </a>
              ?
            </p>
            <div style={{ padding: 12 }}>
              <Button
                bsStyle="danger"
                onClick={() => {
                  this.setState(() => {
                    throw new Error("You broke it on purpose");
                  });
                }}
              >
                Break Things
              </Button>
            </div>
            <small>
              Clicking the button will cause an error. It won't damage anything.
              It's just a way to test how this app responds to unexpected
              stimuli. You know you want to...
            </small>
          </div>
        );
      },
    },
    {
      path: "/dump-env",
      component: () => {
        return (
          <table className="table table-striped">
            {Object.entries(import.meta.env).map(([k, v]) => (
              <tr>
                <th>{k}</th>
                <td>{JSON.stringify(v)}</td>
              </tr>
            ))}
          </table>
        );
        // return (<div>{JSON.stringify(import.meta.env)}</div>)
      },
    },
    {
      path: "/:something",
      component: WildcardComponent,
    },
    {
      path: "/",
      component: () => {
        window.location.href =
          import.meta.env.VITE_ORG_SITE_PROGRAM_PAGE ||
          "https://www.acp-usa.org/mentoring-program";
        return <div />;
      },
    },
  ];

  private readonly adminRoutes: RouteObject[] = [
    {
      path: "/admin/logs/:appId",
      component: Protected(AsyncAppLogs),
    },
    {
      path: "/admin",
      component: Protected(AsyncAdmin),
    },
  ];

  private adminLayout = (
    C: React.ComponentType<RouteComponentProps<any>>,
    props: RouteComponentProps<any>,
  ) => {
    return (
      <Grid
        style={{
          background: "white",
          maxWidth: "1400px",
          minWidth: "1200px",
          border: "1px solid #666",
          padding: "0 15px 14px 15px",
        }}
      >
        <C {...props} />
      </Grid>
    );
  };

  private renderAdminRoutes() {
    return this.adminRoutes.map((route, ind) => {
      return (
        <Route
          key={`public-${ind}`}
          path={route.path}
          render={(props: any) => this.adminLayout(route.component, props)}
        />
      );
    });
  }

  private publicLayout = (
    C: React.ComponentType<RouteComponentProps<any>>,
    props: RouteComponentProps<any>,
  ) => {
    return (
      <Grid
        style={{
          background: "white",
          maxWidth: "730px",
          border: "1px solid #666",
          padding: "0 15px 14px 15px",
        }}
      >
        <C {...props} />
      </Grid>
    );
  };

  private renderPublicRoutes() {
    return this.publicRoutes.map((route, ind) => {
      return (
        <Route
          key={`admin-${ind}`}
          path={route.path}
          render={(props) => this.publicLayout(route.component, props)}
        />
      );
    });
  }
}

export default App;
